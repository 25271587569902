<script lang="ts">

    import TextArea from "../atoms/TextArea.svelte";
    import {t} from "../../i18n/i18n";
    import {NotificationType} from "../types/notification";
    import {addNotification} from "../stores";
    import {getContext} from "svelte";
    import {ButtonIcons, LotState} from "../types/enums";
    import ActualTargetComparison from "../organisms/ActualTargetComparison.svelte";
    import SecondaryButton from "../atoms/SecondaryButton.svelte";
    import type {Writable} from "svelte/store";
    import type {Lot} from "../types/lot";
    import {type Offer, OfferState} from "../bid/types/offer";
    import type {ContactData} from "../types/contactData";
    import {savePublicOptimizerComment} from "../bid/offerUtils";
    import OptimizerPagesTemplate from "./OptimizerPagesTemplate.svelte";
    import type {Deal} from "../bid/types/deal";
    import {NextButtonWantedObject} from "../bid/types/nextButtonDto";

    const lot: Writable<Lot> = getContext("lot");
    const offerMaterial: Writable<Offer> = getContext("offerMaterial");
    const offerMaterialWithTransport: Writable<Offer> = getContext("offerMaterialWithTransport");
    const deal: Writable<Deal> = getContext("deal");


    export let optimizerContactData: ContactData;

    let anonymizeAddress: boolean = true;

    if ($lot.state === LotState.OPTIMIZED) {
        let notification = {
            message: $t('UI.Accepted.successful'), type: NotificationType.SUCCESS, timeout: 0, dismissible: false
        };
        addNotification(notification);
    }

    function sendSaveRequest() {
        if ($offerMaterial.offerState === OfferState.ACCEPTED) {
            savePublicOptimizerComment($offerMaterial.optimizerPublicComment, $offerMaterial.publicId);
        } else {
            savePublicOptimizerComment($offerMaterialWithTransport.optimizerPublicComment, $offerMaterialWithTransport.publicId);
        }
    }


    $: {
        if ($lot) {
            anonymizeAddress = !($lot.state === LotState.HANDLING
                || $lot.state === LotState.ACCOUNTING
                || $lot.state === LotState.COMPLETED);
        }
    }

</script>


<OptimizerPagesTemplate {anonymizeAddress} recipientData={optimizerContactData}
                        projectNavigationCurrentId={$deal?.publicId} projectNavigationReturnUrl="/lots/won"
                        projectNavigationWantedObject={NextButtonWantedObject.DEAL}>

    {#if $lot?.state === LotState.ACCOUNTING || $lot?.state === LotState.HANDLING || $lot?.state === LotState.COMPLETED}
        <div class="row">
            <div class="col-lg-12 col-sm-12">
                <ActualTargetComparison readOnly={$lot?.state !== LotState.HANDLING}/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-xs-12">
                {#if $offerMaterial.offerState === OfferState.ACCEPTED}
                    <TextArea id="optimizerPublicComment" bind:text={$offerMaterial.optimizerPublicComment}
                              title={$t('UI.remarks.label')}
                              placeholder={$t("UI.pubRemarks.placeholder.detailedInfo")}/>
                {:else}
                     <TextArea id="optimizerPublicComment"
                               bind:text={$offerMaterialWithTransport.optimizerPublicComment}
                               title={$t('UI.remarks.label')}
                               placeholder={$t("UI.pubRemarks.placeholder.detailedInfo")}/>

                {/if}
                <div class="align-right" style="margin-top: 1.125rem">
                    <SecondaryButton id="saveOptimizerCommentBtn" label={$t("UI.saveRemark")}
                                     leftIcon={ButtonIcons.CHECKMARK}
                                     on:click={sendSaveRequest}/>
                </div>
            </div>
        </div>
    {/if}

</OptimizerPagesTemplate>
<style lang="scss">
  @import "../../styles/pubPage";
</style>
