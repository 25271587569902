<script lang="ts">

    import AutocompleteInputField from "./AutocompleteInputField.svelte";
    import type {Writable} from "svelte/store";
    import {createEventDispatcher, getContext, tick} from "svelte";
    import type {FontProps} from "../types/fontProps";
    import type {AutocompleteFieldConfig} from "../types/autocompleteFieldConfig";
    import {getFormAcConfigFetch} from "../types/autocompleteFieldConfig";
    import {t} from "../../i18n/i18n";
    import {inputFieldFormats} from "../utils/formatters";
    import InputField from "../molecules/InputField.svelte";
    import type {AddressData} from "../types/addressData";
    import {addressSchema, copyAddressData, DefaultAddressData} from "../types/addressData";
    import {getFormErrors} from "../utils/formValidation";
    import {clearFormErrors} from "../map/fak/utils/fakUtils";
    import {fetchUtils} from "../utils/fetchUtils";

    export let addressData: AddressData;
    export let fontProps: FontProps;
    export let required: boolean = false;
    export let errorPath: string;
    export let isEditable: boolean = false;
    export let outerId: string = ""; // used to detect if another lot or project is selected
    export let idPrefix: string = "";

    const dispatch = createEventDispatcher();

    let formErrors: Writable<[{ path: string, message: string }]> = getContext("formErrors");
    let city = addressData?.city ? {text: addressData.city} : null;
    let street = getStreetName() ? {text: getStreetName()} : null;
    let streetNumber = getStreetNumber();
    let zip = addressData?.zip ? {text: addressData.zip} : null;
    let cityOptions = city ? [city] : [];
    let streetOptions = street ? [street] : [];
    let zipOptions = zip ? [zip] : [];

    let unique = {};

    let cityAutocompleteConfig: AutocompleteFieldConfig = getFormAcConfigFetch('city-data', false, 'id', 'text', !isEditable, false);
    setConfig(cityAutocompleteConfig, "/api/city-data/autocomplete/svelte?qry=[query]&zip=" + (zip || "") + "&street=" + (street || ""));
    let zipAutocompleteConfig: AutocompleteFieldConfig = getFormAcConfigFetch('zip-data', false, 'id', 'text', !isEditable, false);
    setConfig(zipAutocompleteConfig, "/api/zip-data/autocomplete/svelte?qry=[query]&city=" + (city || "") + "&street=" + (street || ""));
    let streetAutocompleteConfig: AutocompleteFieldConfig = getFormAcConfigFetch('street-data', false, 'id', 'text', !isEditable, false);
    setConfig(streetAutocompleteConfig, "/api/street-data/autocomplete/svelte?qry=[query]&zip=" + (zip || "") + "&city=" + (city || ""));

    function setDataAndOptions() {
        city = addressData?.city ? {text: addressData.city} : null;
        street = getStreetName() ? {text: getStreetName()} : null;
        streetNumber = getStreetNumber();
        zip = addressData?.zip ? {text: addressData.zip} : null;
        cityOptions = city ? [city] : [];
        streetOptions = street ? [street] : [];
        zipOptions = zip ? [zip] : [];
        unique = {};
    }

    // Be careful setting config values later from stores (in this case, placeholder $t store), since the execution order
    // is not guaranteed here. In this case, this lead to a problem where the placeholder was loaded a little bit later
    // in the AutocompleteInputField component. The placeholder was set as label there, marked as required but was indeed
    // null at this point in time.
    function setConfig(config: AutocompleteFieldConfig, fetch: string) {
        config.creatable = true;
        config.creatablePrefix = "";
        config.valueAsObject = true;
        config.delimiter = ";";
        config.fetch = fetch;
        config.strictMode = false;
    }

    async function autocompleteValueChanged() {
        await tick();
        await preFetchOptions();
        // we need to set "unique" after changing the fetch address in the configs since "unique" is set as key
        // in order to update the components purposefully, otherwise a race condition will break svelecte
        updateFetchURLs();
        unique = {};
        await tick();
        // FUCKUP EXPLANATION BELOW
        // together with strictMode=false, we need this in order to not have the selection reset by svelecte
        // svelecte does not find the selection after the update in the options somehow, when only setting strictMode = false
        // we would not see the item actually selected, therefore we only give an options list with the selected item, if an item is selected
        // if nothing is selected, the options list remains prefetched
        if (city) {
            cityOptions = [city];
        }
        if (zip) {
            zipOptions = [zip];
        }
        if (street) {
            streetOptions = [street];
        }
        await tick();


        if (!addressData) {
            addressData = DefaultAddressData();
        }
        addressData.street = getFullStreet();
        addressData.zip = zip?.text ?? "";
        addressData.city = city?.text ?? "";

        clearFormErrors();

        if (city && zip && street) {
            try {
                addressSchema.validateSync(copyAddressData(addressData), {abortEarly: false});
            } catch (error) {
                $formErrors = getFormErrors(error, errorPath + '.');
                return;
            }
        }

        dispatch("change");
    }

    async function preFetchOptions() {
        if (!city) {
            await fetchUtils.get(
                "/api/city-data/autocomplete/svelte?qry=&zip=" + (zip?.text ?? "") + "&street=" + (street?.text ?? ""),
                false).then(data => cityOptions = data).catch();
        }
        if (!zip) {
            await fetchUtils.get(
                "/api/zip-data/autocomplete/svelte?qry=&city=" + (city?.text ?? "") + "&street=" + (street?.text ?? ""),
                false).then(data => zipOptions = data).catch();
        }
        if (!street) {
            await fetchUtils.get(
                "/api/street-data/autocomplete/svelte?qry=&zip=" + (zip?.text ?? "") + "&city=" + (city?.text ?? ""),
                false).then(data => streetOptions = data).catch();
        }
    }

    function updateFetchURLs() {
        if (!city) {
            cityAutocompleteConfig.fetch = "/api/city-data/autocomplete/svelte?qry=[query]&zip=" + (zip?.text ?? "") + "&street=" + (street?.text ?? "");
        }
        if (!zip) {
            zipAutocompleteConfig.fetch = "/api/zip-data/autocomplete/svelte?qry=[query]&city=" + (city?.text ?? "") + "&street=" + (street?.text ?? "");
        }
        if (!street) {
            streetAutocompleteConfig.fetch = "/api/street-data/autocomplete/svelte?qry=[query]&zip=" + (zip?.text ?? "") + "&city=" + (city?.text ?? "");
        }

    }

    function getFullStreet() {
        let tmpStreet = street?.text ?? "";
        let tmpNumber = streetNumber ? " " + streetNumber : "";
        const coordinatePattern = /^-?\d{1,2}(\.\d+)?,\s*-?\d{1,3}(\.\d+)?$/;
        if (coordinatePattern.test(tmpStreet)) {
            return tmpStreet;
        }
        return tmpStreet ? tmpStreet + tmpNumber : "";
    }

    function getStreetName() {
        if (addressData && addressData.street) {
            const coordinatePattern = /^-?\d{1,2}(\.\d+)?,\s*-?\d{1,3}(\.\d+)?$/;
            if (coordinatePattern.test(addressData.street)) {
                const match = addressData.street.match(coordinatePattern);
                return match ? match[0] : null;
            }

            const match = addressData.street.match(/^\D+/);
            return match ? match[0].trim() : null;
        }
        return null;
    }

    function getStreetNumber() {
        if (addressData && addressData.street) {
            const coordinatePattern = /^-?\d{1,2}(\.\d+)?,\s*-?\d{1,3}(\.\d+)?$/;
            if (coordinatePattern.test(addressData.street)) {
                return null;
            }

            const match = addressData.street.match(/\d+[a-zA-Z]*\b/);
            return match ? match[0] : null;
        }
        return null;
    }

    $: disabled = !isEditable;
    $: outerId, setDataAndOptions();

</script>

<div>
    <div class="project-data-address">
        <!-- Need the key here to render it as a whole and on purpose. For more information look in autocompleteValueChanged() -->
        {#key unique}
            <div style="grid-area: city">
                <AutocompleteInputField bind:value={city} bind:options={cityOptions} id="{idPrefix}-input-city"
                                        {disabled} {required} {fontProps} label={$t('UI.map.place.ph')}
                                        autocompleteConfig={cityAutocompleteConfig} errorPath={errorPath + '.city'}
                                        disableCreateRowLabel
                                        on:change={autocompleteValueChanged}>
                </AutocompleteInputField>
            </div>
            <div style="grid-area: zip">
                <AutocompleteInputField bind:value={zip} bind:options={zipOptions} id="{idPrefix}-input-zip"
                                        {disabled} {required} {fontProps} label={$t('UI.map.zip.ph')}
                                        autocompleteConfig={zipAutocompleteConfig} errorPath={errorPath + '.zip'}
                                        disableCreateRowLabel
                                        on:change={autocompleteValueChanged}>
                </AutocompleteInputField>
            </div>
            <div style="grid-area: street">
                <AutocompleteInputField bind:value={street} bind:options={streetOptions} id="{idPrefix}-input-street"
                                        {disabled} {required} {fontProps}
                                        autocompleteConfig={streetAutocompleteConfig} errorPath={errorPath + '.street'}
                                        label={$t('UI.map.streetOrCoordinates.ph')} disableCreateRowLabel
                                        on:change={autocompleteValueChanged}>
                </AutocompleteInputField>
            </div>
            <div style="grid-area: street-number">
                <InputField bind:value={streetNumber} id="{idPrefix}-input-street-number"
                            {fontProps}
                            readOnly={disabled} format={inputFieldFormats.FULL}
                            placeholder={$t('AddressData.street.number')} errorPath={errorPath + '.streetNumber'}
                            on:change={autocompleteValueChanged}>
                </InputField>
            </div>
        {/key}
    </div>
</div>

<style lang="scss">
  @import "../../styles/global";
  @import "../../styles/autocomplete";

  .project-data-address {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "city zip"
    "street street-number";
    grid-gap: 0.938rem; //same as other fields on the FAK
    width: 100%;

    & > div {
      width: 100%;
    }
  }

</style>
